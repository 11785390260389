<template>
  <a
    :href="data.link"
    target="_blank"
    class="events-grid__item events-grid__item--hardcode"
    :class="data.class"
  >
    <div class="events-grid__img">
      <img :src="data.img" />
      <div class="status events-grid__status status--competition">
        {{ data.status }}
      </div>
    </div>
    <div class="events-grid__title">{{ data.title }}</div>
    <div class="events-grid__text">{{ data.description }}</div>
    <div class="events-grid__footer">
      <Button> Подать заявку </Button>
    </div>
  </a>
</template>

<script>
import Button from "@/components/Blocks/Button.vue";

export default {
  name: "CardEventHardcode",
  components: { Button },
  props: ["data"],
  computed: {
    className() {
      return "events-grid__item--disabled";
    },
  },
};
</script>

<style lang="scss">
.events-grid {
  .events-grid__item--disabled {
    opacity: 0.4;
    pointer-events: none;
    .btn {
      display: none;
    }
  }
  &__item {
    padding: 20px;
    border: 1px solid var(--background--btn--profile--hover);
    border-radius: 5px;
    transition: all 0.22s ease-in-out;
    position: relative;
    z-index: 100;
    @include adaptive(tablet-small) {
      padding: 15px 20px;
    }
    @media (min-width: 991px) {
      &:hover {
        transform: scale(1.05);
        border-color: var(--event--hover);
      }
    }
    &--hardcode {
      .btn {
        margin-left: auto;
      }
    }
  }
  &__img {
    position: relative;
    min-height: 215px;
    border-radius: 5px;
    margin-bottom: 24px;
    & img {
      @include full-absolute();
      object-fit: cover;
      border-radius: 5px;
    }
    & .events-grid__status {
      position: absolute;
      right: 14px;
      bottom: -13px;
    }
    @include adaptive(phone) {
      & img {
        object-fit: contain;
      }
    }
  }
  &__title {
    font-size: 2.2rem;
    line-height: 2.7rem;
    font-weight: 500;
    margin-bottom: 4px;
    color: var(--text--color);
  }
  &__text {
    margin-bottom: 14px;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: var(--text--color);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
  }
  &__date {
    color: var(--input--border);
  }
}
</style>
